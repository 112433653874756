.search-container {
  position: relative;
 
  width: 100%;
}

.overlayInput{
  position: fixed;
  top: 0;
  
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.333); 
  z-index: 999; 
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  place-content: center;
}
.modalInput{
  background-color: var(--blanco);
  width: 30rem!important;
  height: 80vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 20px;
  border: none;
  padding: 20px;
}
.sectionSearch h3{
  color: var(--text-color2);
  padding: 10px;
  font-weight: 500;
}
.modalSearch {
  top: 4rem;
  left: 0;
  background-color: var( --gris);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  padding: 10px;
  z-index: 10000000000!important;
  width: 100%;
  overflow-y:scroll;
  border-radius: 7px ;
  transition: all .40s ease;
  margin-top: 1rem;
}
.modalSearch img{
width: 50px;
height: 50px;
object-fit: cover;
border-radius: 10px;

}


.modalSearch p {
  display: block;

  text-decoration: none;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 0px;
  color: var(--text-color2)!important;
}

.inputSearch{
  background-color: var(  --gris)!important;
  border-radius: 20px;
  border: 0.3px solid #00000037;
  padding: 5px 10px;
  width: 100%;
  color: var(--text-color2);
  display: flex;
  gap: 10px;
  align-items: center;

}

.input{
  width: 90%;
  height: 100%;
  padding: 6px 6px;
  background-color: transparent;
  border: none;
}
.inputSerch{
  width: 100%;
  height: 100%;
  padding: 6px 6px;
  background-color: transparent;
  border: none;
}
.modalSearch a{
  text-decoration: none;
  display: flex;
  align-items: center;
  align-content: center;
  gap: 20px;
  padding: 10px 10px;
  color: var(--color1);
  margin-bottom: 1rem;
}
.modalSearch a:hover p{

  color: var(--blanco)!important;
}
.modalSearch a:hover {
 background-color: var(--color1);
 border-radius: 7px;
 transition: 0.5s ease all;
 color: var(--blanco)!important;

}
.modalSearch button{
  background-color: transparent;
  border: none;

}
.search-icon{
background-color: var(--color1);
color: var(--blanco);
padding: 8px;
font-size: 14px;
cursor: pointer;
border-radius: 100%;
}


.modalSearch::-webkit-scrollbar{
  width: 7px;
  height: 7px;
  cursor: pointer;
}

.modalSearch::-webkit-scrollbar-thumb{
   background-color: var(--color1);
   width: 10px;
   border-radius:20px ;
   cursor: pointer;
}
.inputSerch span{
display: flex;
width: 100%;
}


@media (max-width: 1024px){

.inputSerch{
  display: none;
}
.modal p {
  font-size: 13px;
}
.modal a{
  
  gap: 10px;
  padding: 0px;

}
.modalInput{
  width: 100%!important;
  height: 100vh;
  border-radius: 20px 20px 0 0 ;

}

.overlayInput{
justify-content: end;
align-items: end;
place-content: end;
}
.search-icon{
  font-size: 16px;
  }
  
}
